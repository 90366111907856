import React from "react"
import { Line } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeaderPage, Services } from "../components/structure"

import portfolio from "../images/stats/portfolio.svg"

export default function Portfolio() {
  return (
    <Layout>
      <SEO title="Portfolio" />
      <HeaderPage
        src={portfolio}
        color={props => props.theme.color.complimentry}
        bgColor={props => props.theme.color.bgFirst}
        title="Check our few works which we had created for our clients"
        catchy="Portfolio"
      />
      <Line />
      <Services />
    </Layout>
  )
}
